html {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  background-color: white;
  color: black;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

canvas {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.loading {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  font-size: 20px;
  color: cadetblue;
  background-color: rgba(white, 0.5);
}
