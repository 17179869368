html {
  margin: 0;
  padding: 0;
}

body {
  color: #000;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Segoe UI, Tahoma, Geneva, Verdana, sans-serif;
  font-size: 18px;
  overflow: hidden;
}

canvas {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.loading {
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  color: #5f9ea0;
  background-color: #ffffff80;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

/*# sourceMappingURL=index.25ad558f.css.map */
